<template>
  <div class="auth-wrapper auth-v1">
    <amplify-authenticator username-alias="email" style="--container-height: 47vh;">
      <amplify-sign-in
        :username-alias="signInConfig.usernameAlias"
        slot="sign-in"
      >
        <div slot="federated-buttons"></div>
      </amplify-sign-in>
      <amplify-sign-up
        :header-text="signUpConfig.headerText"
        :username-alias="signUpConfig.usernameAlias"
        :form-fields.prop="signUpConfig.formFields"
        slot="sign-up"
      />
    </amplify-authenticator>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="173"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
    >

    <!-- tree -->
    <v-img
      class="auth-tree"
      width="247"
      height="185"
      src="@/assets/images/misc/tree.png"
    ></v-img>

    <!-- tree  -->
    <v-img
      class="auth-tree-3"
      width="377"
      height="289"
      src="@/assets/images/misc/tree-3.png"
    ></v-img>
  </div>
</template>

<script>
import { Auth } from 'aws-amplify';

export default {
  name: 'Login',
  data() {
      return {
      signInConfig: {
        usernameAlias: "email",
        hiddenDefaults: ['phone_number']
      },
      signUpConfig: {
        usernameAlias: "email",
        formFields: [
          { type: "name", label: "Name", placeholder: 'Enter your full Name', required: true},
          { type: "email", label: "Email", required: true },
          { type: "password" },
        ]
      }
    }
  },
  mounted() {
    Auth.signOut();
  }
};
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
